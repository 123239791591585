const getBrandFromLocation = () => {
  const location = window.location.hostname;
  return location.split("-")[0];
};

const getSubdomainFromLocation = () => {
  const location = window.location.hostname;
  return location.split(".").slice(1).join(".");
};

const getAccessBrand = () => {
  const brand = getBrandFromLocation();
  const subdomain = getSubdomainFromLocation();

  const brandUrls = {
    hourglass: "https://api-crm-mobile-dev.dosetech.co",
    linecrm: "https://api-crm-mobile-dev.dosetech.co",
    d: "https://api-crm-mobile-dev.dosetech.co",
    dcrm: "https://api-dcrm-demo-mobile.dosetech.co",
    clarins: "https://clubbeaute-mobile-api.dosetech.co"
  };

  if (brandUrls[brand]) {
    return brandUrls[brand];
  }

  if (process.env.NODE_ENV === "development") {
    return "https://api-crm-mobile-dev.dosetech.co";
  }

  return `${window.location.protocol}//api-${brand}-mobile-${process.env.VUE_APP_MODE}.${subdomain}`;
};

export { getAccessBrand };
